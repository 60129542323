<template>
  <div class="row">
    <div class="col-md-12 col-lg-8 col-xl-9 tripsWrapper" id="tripsWrapper">
      <ul class="nav nav-pills nav-fill" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active upper-labels"
            id="current-upcomming-tab"
            data-bs-toggle="tab"
            data-bs-target="#current-upcomming"
            type="button"
            role="tab"
            aria-controls="current-upcomming"
            aria-selected="true"
          >
            Current upcomming service needs
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link upper-labels"
            id="complete-tab"
            data-bs-toggle="tab"
            data-bs-target="#complete"
            type="button"
            role="tab"
            aria-controls="complete"
            aria-selected="false"
          >
            Complete services
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link upper-labels"
            id="cancel-tab"
            data-bs-toggle="tab"
            data-bs-target="#cancel"
            type="button"
            role="tab"
            aria-controls="cancel"
            aria-selected="false"
          >
            Cancelled services
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="current-upcomming"
          role="tabpanel"
          aria-labelledby="current-upcomming-tab"
        >
          <div class="trips" v-if="$data.currentUpcomingItems.length > 0">
            <MyTravelNeedTravellerItem
              v-for="(item, index) in $data.currentUpcomingItems"
              :item="item"
              :key="index"
            />
          </div>
          <div v-else>
            <h3>There's no data</h3>
          </div>
        </div>
        <div class="tab-pane fade" id="complete" role="tabpanel" aria-labelledby="complete-tab">
          <div class="trips" v-if="$data.completedItems.length > 0">
            <MyTravelNeedTravellerItem
              v-for="(item, index) in $data.completedItems"
              :item="item"
              :key="index"
            />
          </div>
          <div v-else>
            <h3>There's no data</h3>
          </div>
        </div>
        <div class="tab-pane fade" id="cancel" role="tabpanel" aria-labelledby="cancel-tab">
          <div class="trips" v-if="$data.cancelledItems.length > 0">
            <MyTravelNeedTravellerItem
              v-for="(item, index) in $data.cancelledItems"
              :item="item"
              :key="index"
            />
          </div>
          <div v-else>
            <h3>There's no data</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-4 col-xl-3 calendarWrapper" id="calendarWrapper">
      <div id="menu">
        <span id="menu-navi">
          <button type="button" class="btn btn-primary btn-sm move-today" data-action="move-today">
            Today
          </button>
          <button
            type="button"
            class="btn btn-outline-dark btn-sm move-day"
            data-action="move-prev"
          >
            <i class="fa fa-arrow-circle-left" data-action="move-prev"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-dark btn-sm move-day"
            data-action="move-next"
          >
            <i class="fa fa-arrow-circle-right" data-action="move-next"></i>
          </button>
        </span>
        <span id="renderRange" class="render-range"></span>
      </div>
      <div id="calendar" style="height: 500px"></div>
    </div>
  </div>
</template>

<script>
import MyTravelNeedTravellerItem from './MyTravelNeedTravellerItem.vue';
export default {
  name: 'MyTravelNeedTraveller',
  layout: {
    name: 'traveller',
  },
  components: { MyTravelNeedTravellerItem },
  data: function () {
    return {
      currentUpcomingItems: [],
      completedItems: [],
      cancelledItems: [],
    };
  },
  created() {
    let self = this;
    let apiUrl = process.env.VUE_APP_API_URL;
    let token = this.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    let data = {
      type: 'current_upcoming',
    };

    const formData = Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
    // const formData = new FormData();
    // formData.append('type', 'current_upcoming');
    fetch(apiUrl + 'traveller/get-trips', {
      method: 'POST',
      headers: headersObject,
      body: formData,
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }
        self.$data.currentUpcomingItems = resData.items;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/new/content/trip_list';
</style>
