<template>
  <div
    :class="{
      row: true,
      'mb-1': true,
      'border-bottom': true,
      'row-people text-people': $props.item.service_types == 1,
      'row-package text-package': $props.item.service_types == 2,
      'row-product text-product': $props.item.service_types == 3,
    }"
  >
    <div class="col-md-3">
      <div class="row">
        <div class="col col-sm-12">
          <p class="font-medium text-danger mb-0">Date range from</p>
          <p class="font-normal mb-0">{{ $props.item.departure_date }}</p>
        </div>
        <div class="col">
          <p class="font-medium text-danger mb-0">Date range to</p>
          <p class="font-normal">{{ $props.item.arrival_date }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-5">
      <div class="row">
        <div class="col col-sm-12">
          <div class="">
            <img class="media-object" src="images/flight-departure-icon.png" alt="" width="24" />
            <p class="font-medium text-danger mb-0 d-inline-block">Fly from</p>
          </div>
          <div class="">
            <strong>{{ $props.item.o_name }},</strong>
            <span class="">{{ $props.item.o_country }} ({{ $props.item.o_code }})</span>
          </div>
        </div>
        <div class="col col-sm-12">
          <div class="text-danger font-medium">
            <img class="media-object" src="images/flight-arrival-icon.png" alt="" width="24" />
            Fly to
          </div>
          <div class="" style="">
            <strong>{{ $props.item.d_name }},</strong>
            <span class="">{{ $props.item.d_country }} ({{ $props.item.d_code }})</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <div class="col">
          <p class="font-medium text-danger mb-0">Services</p>
          <p class="font-normal mb-0">
            <span v-if="$props.item.service_types == 1">People</span>
            <span v-if="$props.item.service_types == 2">Package</span>
            <span v-if="$props.item.service_types == 3">Product</span>
          </p>
        </div>
        <div class="col">
          <p class="font-medium text-danger mb-0">Created</p>
          <p class="font-normal mb-0">{{ $props.item.created_at }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="font-medium text-danger mb-0">Status</p>
          <div class="d-flex align-items-center">
            <span class="media-left pull-left status-dot bg-green mr-10"></span>
            <span class="font-normal d-inline-block">{{ $props.item.trip_status }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyTravelNeedTravellerItem',

  props: ['item'],
  mounted() {
    // console.log(this.$props.item.departure_date);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/new/content/trip_list';
</style>
